import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Paper, Typography, TextField, Button, Stepper, Step, StepLabel } from '@mui/material';
import PasswordStrengthIndicator from '../components/PasswordStrengthIndicator';
import { registerUser } from '../services/api';
import { setTokens } from '../utils/auth';
import { jwtDecode } from 'jwt-decode';

const SignUpPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [birthday, setBirthday] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [token, setToken] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const pw_regex = new RegExp("^(?:(?=.*\\d)(?=.*[A-Z])(?=.*[a-z])|(?=.*\\d)(?=.*[^A-Za-z0-9])(?=.*[a-z])|(?=.*[^A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z])|(?=.*\\d)(?=.*[A-Z])(?=.*[^A-Za-z0-9]))[A-Za-z0-9!~<>,;:_=?*+#.\"&§%°()\\|\\[\\]\\-\\$\\^\\@\\/]{8,128}$");


  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlToken = params.get('token');
    if (urlToken) {
      try {
        setToken(urlToken);
        const decodedToken: any = jwtDecode(urlToken);
        setEmail(decodedToken.email);
      } catch (error) {
        console.error('Bad Token:', error);
        navigate('/');
      }
    } else {
      navigate('/login');
    }
  }, [location, navigate]);

  const isPasswordValid = (password: string): boolean => {
    return Boolean(password.match(pw_regex));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (activeStep === 0) {
      if (isPasswordValid(password)) {
        setActiveStep(1);
        setPasswordError('');
      } else {
        setPasswordError('Password must be atleast 8 characters and meet atleast 2 other requirements');
      }
    } else {
      try {
        const response = await registerUser(token, password, birthday);
        setTokens(response.access_token, response.refresh_token);
        navigate('/barcrawl');
      } catch (error) {
        console.error('Registration failed:', error);
        // TODO: Show error message to user
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} style={{ padding: '2rem', marginTop: '2rem' }}>
        <Typography variant="h4" gutterBottom>
          Sign Up
        </Typography>
        <Stepper activeStep={activeStep} alternativeLabel>
          <Step>
            <StepLabel>Create Password</StepLabel>
          </Step>
          <Step>
            <StepLabel>Birthday</StepLabel>
          </Step>
        </Stepper>
        <form onSubmit={handleSubmit}>
          {activeStep === 0 ? (
            <>
              <TextField
                fullWidth
                margin="normal"
                label="Email"
                value={email}
                disabled
              />
              <TextField
                fullWidth
                margin="normal"
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                error={!!passwordError}
                helperText={passwordError}
              />
              <PasswordStrengthIndicator password={password} />
            </>
          ) : (
            <TextField
              fullWidth
              margin="normal"
              label="Birthday"
              type="date"
              value={birthday}
              onChange={(e) => setBirthday(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              required
            />
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: '1rem' }}
          >
            {activeStep === 0 ? 'Next' : 'Sign Up'}
          </Button>
        </form>
        <Typography variant="body2" style={{ marginTop: '1rem' }}>
          Already have an account?{' '}
          <Button color="primary" onClick={() => navigate(`/login?token=${token}`)}>
            Login
          </Button>
          Have a Join Code?{' '}
          <Button color="primary" onClick={() => navigate(`/`)}>
            Enter Join Code
          </Button>
        </Typography>
      </Paper>
    </Container>
  );
};

export default SignUpPage;