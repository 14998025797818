import React, { useState } from 'react';
import { Container, Paper, Typography, TextField, Button, Box } from '@mui/material';
import { config } from '../config';

const RefundRequestPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [paymentId, setPaymentId] = useState('');
  const [submissionStatus, setSubmissionStatus] = useState<'idle' | 'success' | 'error'>('idle');
  const [responseMessage, setResponseMessage] = useState('');

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      alert('Please enter a valid email address');
      return;
    }

    try {
      const response = await fetch(`${config.apiBaseUrl}/account/auto-refund/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, paymentid: paymentId }),
      });

      const data = await response.json();

      setSubmissionStatus(response.ok ? 'success' : 'error');
      setResponseMessage(data.payload.text || 'An error occurred');
    } catch (error) {
      setSubmissionStatus('error');
      setResponseMessage('An error occurred while processing your request');
    }
  };

  const renderContent = () => {
    switch (submissionStatus) {
      case 'success':
        return (
          <Typography variant="body1" style={{ marginTop: '1rem', color: 'green' }}>
            {responseMessage}
          </Typography>
        );
      case 'error':
        return (
          <Typography variant="body1" style={{ marginTop: '1rem', color: 'red' }}>
            {responseMessage}
          </Typography>
        );
      default:
        return (
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Payment ID"
              type="text"
              value={paymentId}
              onChange={(e) => setPaymentId(e.target.value)}
              required
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginTop: '1rem' }}
            >
              Submit Refund Request
            </Button>
          </form>
        );
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} style={{ padding: '2rem', marginTop: '2rem' }}>
        <Box
          component="img"
          sx={{
            width: '100%',
            height: 'auto',
            marginBottom: 2,
          }}
          alt="Bar Crawl Banner"
          src={config.logos.large}
        />
        <Typography variant="h4" gutterBottom>
          Refund Request
        </Typography>
        {renderContent()}
      </Paper>
    </Container>
  );
};

export default RefundRequestPage;
