import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { config } from './config';

// Import pages
import SignUpPage from './pages/SignUpPage';
import LoginPage from './pages/LoginPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import PasswordResetPage from './pages/PasswordResetPage';
import RegisterAndJoinPage from './pages/RegisterAndJoinPage';
import BarCrawlListPage from './pages/BarCrawlListPage';
import JoinBarCrawlPage from './pages/JoinBarCrawlPage';
import PlayPage from './pages/PlayPage';
import AccountSettingsPage from './pages/AccountSettingsPage';
import TempRegisterPage from './pages/TempRegisterPage';
import RefundRequestPage from './pages/RefundRequestPage';

// Import components
import NavBar from './components/NavBar';

// Import auth utility
import { isAuthenticated } from './utils/auth';

// Import fonts
import '@fontsource/luckiest-guy';
import '@fontsource/ubuntu';

const theme = createTheme({
  palette: {
    primary: {
      main: config.primaryColor,
    },
    secondary: {
      main: config.secondaryColor,
    },
  },
  typography: {
    fontFamily: '"Ubuntu", sans-serif',
    h1: {
      fontFamily: '"Luckiest Guy", cursive',
    },
    h2: {
      fontFamily: '"Luckiest Guy", cursive',
    },
    h3: {
      fontFamily: '"Luckiest Guy", cursive',
    },
    h4: {
      fontFamily: '"Luckiest Guy", cursive',
    },
    h5: {
      fontFamily: '"Luckiest Guy", cursive',
    },
    h6: {
      fontFamily: '"Luckiest Guy", cursive',
    },
  },
});

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const location = useLocation();
  
  if (!isAuthenticated()) {
    // Preserve the current URL including query parameters when redirecting to login
    return <Navigate to={`/login?redirect=${encodeURIComponent(location.pathname + location.search)}`} replace />;
  }

  return (
    <>
      <NavBar />
      {children}
    </>
  );
};

// Component to handle route restoration
const RouteRestorer: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const intendedRoute = sessionStorage.getItem('intendedRoute');
    if (intendedRoute && location.pathname === '/') {
      sessionStorage.removeItem('intendedRoute');
      // Use the full stored route including query parameters
      navigate(intendedRoute, { replace: true });
    }
  }, [navigate, location]);

  return null;
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <RouteRestorer />
        <Routes>
          {/* Public routes */}
          <Route path="/register" element={<SignUpPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/password-reset" element={<PasswordResetPage />} />
          <Route path="/refund-request" element={<RefundRequestPage />} />
          <Route path="/temp-register" element={<TempRegisterPage />} />
          
          {/* Protected routes */}
          <Route
            path="/barcrawl"
            element={
              <ProtectedRoute>
                <BarCrawlListPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/barcrawl/join"
            element={
              <ProtectedRoute>
                <JoinBarCrawlPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/barcrawl/play/:groupId"
            element={
              <ProtectedRoute>
                <PlayPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/account/settings"
            element={
              <ProtectedRoute>
                <AccountSettingsPage />
              </ProtectedRoute>
            }
          />

          {/* Root route */}
          <Route path="/" element={<RegisterAndJoinPage />} />

          {/* Catch-all route - redirect to root while preserving query params */}
          <Route path="*" element={
            <Navigate 
              to={`/${window.location.search}${window.location.hash}`} 
              replace 
            />
          } />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
