import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Container, Paper, Typography, TextField, Button, Box} from '@mui/material';
import { registerAndJoin } from '../services/api';
import { config } from '../config';

const RegisterAndJoinPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState('');
  const [joinCode, setJoinCode] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const joinCodeParam = searchParams.get('join_code');
    if (joinCodeParam) {
      setJoinCode(joinCodeParam);
    }
  }, [searchParams]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await registerAndJoin(email, joinCode);
      // Show a message to check email
      alert('Please check your email for registration instructions.');
    } catch (error) {
      console.error('Registration failed:', error);
      // TODO: Show error message to user
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} style={{ padding: '2rem', marginTop: '2rem' }}>
      <Box
        component="img"
        sx={{
          width: '100%',
          height: 'auto',
          marginBottom: 2,
        }}
        alt="Bar Crawl Banner"
        src={config.logos.large} 
      />
        <Typography variant="h4" gutterBottom>
          Register and Join
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            fullWidth
            id="join_code"
            margin="normal"
            label="Join Code"
            value={joinCode}
            onChange={(e) => setJoinCode(e.target.value)}
            required
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: '1rem' }}
          >
            Register and Join
          </Button>
        </form>
        <br></br>
        
        <Typography  gutterBottom>
          Have an account? <a href='/login'>Login </a>
        </Typography>
      </Paper>
    </Container>
  );
};

export default RegisterAndJoinPage;
