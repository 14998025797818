export const config = {
  //apiBaseUrl: process.env.REACT_APP_API_URL || 'http://localhost:8000',
  apiBaseUrl: 'https://api.barcrawlapp.com',
  appBaseUrl: 'https://play.barcrawlapp.com',
  primaryColor: '#39c1e6',
  secondaryColor: '#fccf07',
  bannerImageUrl: 'https://s3.barcrawlapp.com/images/bcbanner01.png',
  fonts: {
    heading: "'Luckiest Guy', cursive",
    listTitle: "'Luckiest Guy', cursive",
    listDetails: "'Ubuntu', sans-serif",
  },
  icons: {
    beer: 'https://s3.barcrawlapp.com/icons/beer.png',
    mixed_drink: 'https://s3.barcrawlapp.com/icons/mixed_drink.png',
    seltzer: 'https://s3.barcrawlapp.com/icons/seltzer.png',
    shot: 'https://s3.barcrawlapp.com/icons/shot.png',
    wine: 'https://s3.barcrawlapp.com/icons/wine.png',
  },
  logos: {
    small: "https://s3.barcrawlapp.com/images/logos/logo_small_tsp.png" ,
    large: "https://s3.barcrawlapp.com/images/logos/logo.png",
  },
  isProduction: process.env.REACT_APP_ENV === 'production',
};
/*

export const config = {
  //apiBaseUrl: process.env.REACT_APP_API_URL || 'http://localhost:8000',
  apiBaseUrl: 'http://localhost:8000', //'https://api.jmerlo.com',
  primaryColor: '#39c1e6',
  secondaryColor: '#fccf07',
  bannerImageUrl: 'https://barcrawl.nyc3.cdn.digitaloceanspaces.com/images/banners/bcbanner01.png',
  fonts: {
    heading: "'Luckiest Guy', cursive",
    listTitle: "'Luckiest Guy', cursive",
    listDetails: "'Ubuntu', sans-serif",
  },
  icons: {
    beer: '',
    mixed_drink: '',
    seltzer: '',
    shot: '',
    wine: '',
  },
  logo: '',
  isProduction: process.env.REACT_APP_ENV === 'production',
};*/